import React, { useEffect, useRef, useState } from "react";
import Slider from "@mui/material/Slider";
import "./emiCalculator.css";

export default function EmiCalculator() {
  const [loanAmount, setLoanAmount] = React.useState(10000);
  const [interestRate, setInterestRate] = React.useState(10);
  const [loanTerms, setLoanTerms] = React.useState(1);
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [emi, setEmi] = useState(0);
  const [emiTab, setEmiTab] = useState("M");

  let widths = window.innerWidth;

  const calculateEmi = () => {
    const calculatedLoanTerms = emiTab === "M" ? loanTerms : loanTerms * 12;
    const calculatedMonthlyRate = interestRate / 12 / 100;

    if (calculatedMonthlyRate === 0) {
      const calculatedEmi = loanAmount / calculatedLoanTerms;
      setEmi(calculatedEmi);
      setTotalAmount(calculateEmi * calculatedLoanTerms);
    } else {
      const calculatedEmi =
        (loanAmount *
          calculatedMonthlyRate *
          Math.pow(1 + calculatedMonthlyRate, calculatedLoanTerms)) /
        (Math.pow(1 + calculatedMonthlyRate, calculatedLoanTerms) - 1);
      setEmi(calculatedEmi);
      const calculatedTotalAmount = Math.round(
        calculatedEmi * calculatedLoanTerms
      );
      setTotalAmount(calculatedTotalAmount);
    }
  };

  useEffect(() => {
    calculateEmi();
  }, [loanAmount, loanTerms, interestRate, emiTab]);

  return (
    <>
      <div className="calculator pt-5 pb-5" id="EC">
        <div className="m-0 p-0">
          <div className="">
            <div className="col">
              <div className="heading">
                <h3 className="fw-bold text-center">EMI CALCULATOR</h3>
                <div
                  className="mx-auto mb-4"
                  style={{
                    width: "7rem",
                    height: "4px",
                    backgroundColor: "#c2d72d", // Adjust the color to match the green line
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div className="row w-100 m-0 p-0" id="rowsfirst">
            <div
              className="col-lg-7 col-xs-12 px-2 py-2"
              style={{ backgroundColor: "#DDE6AB" }}
            >
              <div className="sliders col-lg-6 col-md-8 col-sm-11 col-11 mx-auto">
                <div className="LoanAmount pt-5">
                  <div className="row">
                    <div className="col">
                      <h5>Loan Amount</h5>
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="currency-symbol">₹</span>
                      <input
                        type="number"
                        className="form-control-plaintext loan-amount-input"
                        id="loanAmount"
                        value={loanAmount}
                        onChange={(e) => setLoanAmount(e.target.value)}
                      />
                    </div>
                    <div className="underline"></div>
                  </div>
                </div>
                <div className="SelectEmiOption pt-3">
                  <div className="row">
                    <div className="col">
                      <h5>Loan Terms</h5>
                    </div>
                    <div className="d-flex align-items-center">
                      <input
                        type="number"
                        className="form-control-plaintext loan-amount-input"
                        id="emiRate"
                        value={loanTerms}
                        style={{ width: "25%" }}
                        onChange={(e) => setLoanTerms(e.target.value)}
                      />
                      <span
                        className="p-2 cursor-pointer"
                        style={{
                          fontSize: "8px",
                          fontWeight: emiTab === "M" ? "bold" : "500",
                          backgroundColor:
                            emiTab === "M" ? "#023047" : "#DDE6AB",
                          color: emiTab === "M" ? "#ffffff" : "black",
                          borderRadius: 10,
                        }}
                        onClick={() => setEmiTab("M")}
                      >
                        Months
                      </span>
                      <span
                        className="p-2 cursor-pointer"
                        style={{
                          fontSize: "8px",
                          fontWeight: emiTab === "Y" ? "bold" : "500",
                          backgroundColor:
                            emiTab === "Y" ? "#023047" : "#DDE6AB",
                          color: emiTab === "Y" ? "#ffffff" : "black",
                          borderRadius: 10,
                        }}
                        onClick={() => setEmiTab("Y")}
                      >
                        Years
                      </span>
                    </div>
                    <div className="underline"></div>
                  </div>
                </div>
                <div className="InterestRate pt-3">
                  <div className="row">
                    <div className="col">
                      <h5>Interest Rate</h5>
                    </div>
                    <div className="d-flex align-items-center">
                      <input
                        type="number"
                        className="form-control-plaintext loan-amount-input"
                        id="interestRate"
                        value={interestRate}
                        style={{ width: "25%" }}
                        onChange={(e) => setInterestRate(e.target.value)}
                      />
                      <span className="" style={{ fontWeight: "bold" }}>
                        %
                      </span>
                    </div>
                    <div className="underline"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-xs-12 p-0 text-white">
              <div className="emi p-0 h-100">
                <div
                  className="d-flex flex-column m-0 p-0 p-4"
                  style={{ height: "60%", backgroundColor: "#A2BD00" }}
                >
                  <div className="col-12">
                    <div className="emivalue">
                      <h6>Monthly Installment:</h6>
                      <h3>₹ {emi.toFixed(2)}</h3>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="calculations">
                      <div className="mt-3">
                        <div className="col">
                          <h6>Principal Amount</h6>
                        </div>
                        <div className="col">
                          <h5 className="values">₹ {loanAmount}</h5>
                        </div>
                      </div>

                      <hr />
                    </div>
                  </div>
                </div>
                <div
                  className="col p-4"
                  style={{ height: "40%", backgroundColor: "#023047" }}
                >
                  <div className="row">
                    <div className="col">
                      <h5 className="h5">Total Amount</h5>
                    </div>
                    <div className="col">
                      <h5 className="h5values">{totalAmount}</h5>
                    </div>
                  </div>

                  <div className="row mt-4 mb-4 px-1 col-lg-6 col-md-6 col-sm-8 col-12">
                    {/* <button className="buttonapply">Apply Now</button> */}
                    <a
                      href="https://play.google.com/store/apps/details?id=com.credfin.money"
                      target="_blank"
                      className="buttonapplynow"
                    >
                      Apply Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
