import React from "react";
import { Link, NavLink } from "react-router-dom";
import playstoreicon from "../../assets/playstore.png";
import logoWhite from "../../assets/fiscalLogo.svg";
import { AiOutlineMail } from "react-icons/ai";
import "./Footer.css";

export default function Footer() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  window.scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  function scrollToSection(sectionId) {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  }
  return (
    <>
      <footer className="text-light" style={{ backgroundColor: "#023047" }}>
        <div className="circle-shape2" onClick={scrollToTop}></div>

        <div className="container pt-3">
          <div className="f-items default-padding pt-5">
            <div className="row">
              <div className="col-lg-3 col-md-6 item">
                <div className="f-item about">
                  <Link
                    className="nav-link ps-0"
                    to={{
                      pathname: "/",
                    }}
                    onClick={scrollToTop}
                  >
                    <img
                      src={logoWhite}
                      alt="logo"
                      style={{ maxWidth: "200px" }}
                    />
                  </Link>
                  <div className="address">
                    <ul>
                      <li style={{ marginTop: "10px" }}>
                        <div className="icon">
                          {/* <AiOutlineMail className="iconinside" /> */}
                          developer@firstfiscal.in
                        </div>

                        {/* <div className="info">
                          <h5>
                            Email:{" "}
                            <a
                              href="mailto:ajay.kanjalia@gmail.com"
                              style={{
                                cursor: "pointer",
                                fontSize: "17px",
                                fontWeight: "500",
                                color: "white",
                              }}
                            >
                              ajay.kanjalia@gmail.com
                            </a>
                          </h5>
                        </div> */}
                      </li>
                      <li>+91 98183 89402</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 item">
                <div className="f-item link">
                  <h4 className="widget-title">Company</h4>
                  <ul>
                    <li>
                      <Link
                        className="nav-link ps-0"
                        href="#"
                        onClick={() => scrollToTop()}
                      >
                        Home
                      </Link>
                      {/* <a href="#WWO">What we offer</a> */}
                    </li>
                    <li>
                      <Link
                        className="nav-link ps-0"
                        onClick={() => scrollToSection("about")}
                      >
                        About us
                      </Link>
                    </li>

                    <li>
                      <Link
                        className="nav-link ps-0"
                        onClick={() => scrollToSection("products")}
                      >
                        Products
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="nav-link ps-0"
                        onClick={() => scrollToSection("features")}
                      >
                        Features
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 item">
                <div className="f-item link">
                  <ul>
                    <li>
                      <Link
                        className="nav-link ps-0"
                        onClick={() => scrollToSection("EC")}
                      >
                        EMI calculator
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="nav-link ps-0"
                        onClick={() => scrollToSection("sourcing")}
                      >
                        Sourcing Partners
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="nav-link ps-0"
                        onClick={() => scrollToSection("contactUs")}
                      >
                        Contact us
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="nav-link ps-0"
                        to={{
                          pathname: "/home/TermsCondition",
                        }}
                        onClick={() => scrollToSection("offering")}
                      >
                        Our Offering
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 item">
                <div className="row">
                  <div className="btns1">
                    <div className="button">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.credfin.money"
                        target="_blank"
                        className="buttonapplynow"
                      >
                        Apply Now
                      </a>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <h4 className="get_app">Get App Now</h4>
                  <div className="btns1 mt-2">
                    <div className="button">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.credfin.money"
                        target="_blank"
                        className="buttonapply2"
                        style={{ maxWidth: "205px" }}
                      >
                        <img src={playstoreicon} width={"25%"} alt="" />
                        <h6 className="footerbuttontext">
                          Available on <br></br>Playstore
                        </h6>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom mt-3">
          <div className="container">
            <div className="row">
              <div className="col text-center">
                <h5 className="f-b">
                  copyright © First Fiscal Services India Private Limited | All
                  Rights Reserved
                </h5>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
